import { NgModule } from '@angular/core';
import { addNavMenuItem, SharedModule } from '@vendure/admin-ui/core';
import { Permission } from '@vendure/admin-ui/core';

@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuItem(
      {
        id: 'gift-cards',
        label: 'Gift Cards',
        routerLink: ['/extensions/gift-cards'],
        icon: 'e-check',
        requiresPermission: (userPermissions) =>
          userPermissions.includes(Permission.SuperAdmin) ||
          userPermissions.includes('ReadGiftCard'),
      },
      'sales',
    ),
  ],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class GiftCardsNavModule {}
