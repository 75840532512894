export const extensionRoutes = [  {
    path: 'extensions/coupons',
    loadChildren: () => import('./extensions/a98722ba66a854da871e0a704dfada0255863c9cfb8b33393a85115f6892ba0b/coupons-ui-lazy.module').then(m => m.CouponsUiLazyModule),
  },
  {
    path: 'extensions/shop-banners',
    loadChildren: () => import('./extensions/b5cc25aab6ab43b847dddfb19ba7f7f83083d1ac51735b7857843551c3a02d66/ui-lazy.module').then(m => m.ShopBannersUiLazyModule),
  },
  {
    path: 'extensions/gift-cards',
    loadChildren: () => import('./extensions/c3f1ea3308b77251c9f8728dca467a4e52deed1d7056072a554bb5f51d91684d/gift-card-ui-lazy.module').then(m => m.GiftCardsUiLazyModule),
  },
  {
    path: 'extensions/customisable-cta',
    loadChildren: () => import('./extensions/a5e39924f7c6de956c638d3ea030182fabd86dc294cce22329075e5e3688fbc0/cta.module').then(m => m.CustomisableCtaModule),
  },
  {
    path: 'extensions/webhook',
    loadChildren: () => import('./extensions/e252e713b1f4bef5967c2a69e751e76cc8ed72289ebeb11c9ace26a9559c9868/webhook.module').then(m => m.WebhookModule),
  }];
